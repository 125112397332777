<template>
   
    <b-card-actions action-collapse title="Property Pin Section">  
        <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="12">
                    <b-form-group>
                    <label>Property Pin</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="name"
                    >
                          <b-form-input
                                v-model="input.name"
                                placeholder="Property Pin"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                 <b-col md="12" class="q-description">
                    <b-form-group>
                    <label>Description</label>
                    <quill-editor
                    style="height: 150px"
                      v-model="input.description"
                    
                    />
                    
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <h5>Property Info</h5>
                    <hr />
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Developer</label>
                    <v-select
                      v-model="input.devlop_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :reduce="(option) => option.id"
                      :options="devs"
              />
                   
                    </b-form-group>
                    
               
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Property Type</label>
                     <v-select
                      v-model="input.type_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="types"
              />
                  
                    </b-form-group>
                    
               
                </b-col>
                <b-col md="4">
                  <label>Rating</label>
                  <b-form-group>
                    <b-form-rating
                        id="rating-lg-no-border"
                        v-model="input.rating"
                        no-border
                        variant="warning"
                        size="lg"
                        inline
                      />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Year</label>
                     <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="year"
                        >
                         <b-form-input
                            v-model="input.year"
                            placeholder="Year"
                          />
                         <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Price per sqm.</label>
                     <validation-provider
                        #default="{ errors }"
                       
                        name="price_sqm"
                        >
                         <b-form-input
                            v-model="input.price_sqm"
                            placeholder="Price per sqm."
                          />
                         <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Level</label>
                     <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="level"
                        >
                         <b-form-input
                            v-model="input.level"
                            placeholder="Level"
                          />
                         <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group>
                    <label>Comment</label>
                     <b-form-textarea
                          v-model="input.comment"
                            id="comment"
                            placeholder="Comment"
                            rows="3"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <h5>Location</h5>
                    <hr />
                    
                </b-col>
                <b-col md="3">
                      <b-form-group>
                        <label>BTS</label>
                         <v-select
                          v-model="input.bts_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          multiple
                          :options="bts"
                  />
                      </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label>Mrt</label>
                      <v-select
                      v-model="input.mrt_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      multiple
                      :options="mrt"
              />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label>Airport Link</label>
                      <v-select
                      v-model="input.airportlink_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="airportlink"
              />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label>Zone</label>
                      <v-select
                      v-model="input.zone_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      multiple
                      :options="zone"
              />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Distance of BTS to property</label>
                     <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="input.bts_distance"
                        >
                         <b-form-input
                            v-model="input.bts_distance"
                            placeholder="Distance of BTS to property"
                          />
                         <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Distance of MRT to property</label>
                     <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="mrt_distance"
                        >
                         <b-form-input
                            v-model="input.mrt_distance"
                            placeholder="Distance of MRT to property"
                          />
                         <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Distance of Airport link to property</label>
                     <validation-provider
                        #default="{ errors }"
                        rules="integer"
                        name="airportlink_distance"
                        >
                         <b-form-input
                            v-model="input.airportlink_distance"
                            placeholder="Distance of Airport link to property"
                          />
                         <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>

                  </b-form-group>
                </b-col>
                
                <b-col md="12">
                    <h5>Map</h5>
                    <hr />
                </b-col>
                <b-col md="6">
                  <l-map
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="markerLatLng" />
      <l-circle
        :lat-lng="circle.center"
        :radius="circle.radius"
        :color="circle.color"
      />
      <l-polygon
        :lat-lngs="polygon.latlngs"
        :color="polygon.color"
      />
    </l-map>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label>Lattitude</label>
                     <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="lat"
                        >
                         <b-form-input
                            v-model="input.lat"
                             @change="changelatlon"
                            placeholder="Lattitude"
                          />
                         <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>

                  </b-form-group>
                  <b-form-group>
                    <label>Longitude</label>
                     <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="lon"
                        >
                         <b-form-input
                            v-model="input.lon"
                            @change="changelatlon"
                            placeholder="Longitude"
                          />
                         <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col md="12">
                    
                    <hr />
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label>Responsible By </label>
                    <vue-autosuggest
                      ref="autocomplete"
                      v-model="created_by"
                      :suggestions="suggestionscb"
                      :input-props="inputProps"
                      :section-configs="sectionConfigs"
                      :render-suggestion="renderSuggestioncb"
                      :get-suggestion-value="getSuggestionValuecb"
                      @input="fetchResultscb"
                    />    
                  </b-form-group>
                  
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label>Active</label>
                    <b-form-checkbox
                        checked="true"
                        class="custom-control-primary"
                        v-model="input.is_active"
                        switch
                      />
                  </b-form-group>  
                  
                </b-col>
                
                <b-col md="12">
                    <h5>Facility</h5>
                    <hr />
                </b-col>
                <b-col md="12">
           
             <b-form-group label="Room">
                    <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="input.facility_id"
                        :options="facilities"
                        text-field="name"
                        value-field="id"
                        name="facility_id"
                        class="demo-inline-spacing"
                    />
                    </b-form-group>
              </b-col>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-col>
                
              </b-row>
            </b-form>
        </validation-observer>
    </b-card-actions>
      

 
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'
/* eslint-disable global-require */
import {
  LMap, LTileLayer, LMarker, LCircle, LPolygon,
} from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'


//import Multiselect from 'vue-bootstrap-multiselect';

//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import router from '@/router'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCardActions,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormRating,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LPolygon,
    quillEditor
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        id:router.currentRoute.params.id,
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 15,
        center: [47.313220, -1.319482],
        markerLatLng: [47.313220, -1.319482],
        circle: {
          center: [47.313220, -1.319482],
          radius: 4500,
          color: '#EA5455',
        },
        polygon: {
          //latlngs: [[47.2263299, -1.6222], [47.21024000000001, -1.6270065], [47.1969447, -1.6136169], [47.18527929999999, -1.6143036], [47.1794457, -1.6098404], [47.1775788, -1.5985107], [47.1676598, -1.5753365], [47.1593731, -1.5521622], [47.1593731, -1.5319061], [47.1722111, -1.5143967], [47.1960115, -1.4841843], [47.2095404, -1.4848709], [47.2291277, -1.4683914], [47.2533687, -1.5116501], [47.2577961, -1.5531921], [47.26828069, -1.5621185], [47.2657179, -1.589241], [47.2589612, -1.6204834], [47.237287, -1.6266632], [47.2263299, -1.6222]],
          color: '#7367F0',
        },

        devs:[],
        input:{
          lat:null,
          lon:null,
          devlop_id:null,
          type_id:null,
          name: null,
          description:null,
          rating:null,
          year:null,
          price_sqm:null,
          level:null,
          comment:null,
          bts_id:[],
          mrt_id:[],
          
          airportlink_id:null,
          zone_id:[],
          bts_distance:null,
          mrt_distance:null,
          airportlink_distance:null,
          is_active:true,
          facility_id:[],
          created_by:null
        },
        onSelected:null,
         type_id:null,
        created_by:null,
        devlop_id:null,
        
        suggestions: [],
        suggestionscb:[],
        types:[],
        bts:[],
       
        mrt:[],
       
        airportlink:[],
        
        zone:[],
       
        facilities:[],
       
        inputProps: {
          id: 'autosuggest__input_ajax',
          placeholder: 'Developer',
          class: 'form-control',
          name: 'develop',
          
        },
        suggestionsType: [],
        inputPropsType: {
          id: 'autosuggest__input_ajax',
          placeholder: 'Responsible By',
          class: 'form-control',
          name: 'created_by',
          
        },
        sectionConfigs:{
          dev: {
       
            label: 'User',
            onSelected: selected => {
               this.input.devlop_id = selected.item.id;
              console.log('select', selected);
              //this.selected = selected.item
            },
          },
          cb: {
       
            label: 'User',
            onSelected: selected => {
               this.input.created_by = selected.item.id;
              console.log('select', selected);
              //this.selected = selected.item
            },
          },
          types: {
             label: 'Type',
            onSelected: selected => {
              // this.input.type_id = selected.item.id;
              console.log('select', selected);
              //this.selected = selected.item
            },
          }
       
        }
     
    }
  },
  created() {
     
     if(this.id != undefined){
        this.getFacs();
        this.getData();
        this.getBts();
        this.getMrt();
        this.getZone();
       
      }

      this.getDevelops();
       this.getTypes();
     axios.get('transit/v1/listtype/bts')
      .then(res => { 
       // console.log('res',res);
        this.bts = res.data.data; 
      })
      axios.get('transit/v1/listtype/mrt')
      .then(res => { 
       // console.log('res',res);
        this.mrt = res.data.data; 
      })
      axios.get('transit/v1/listtype/airport')
      .then(res => { 
       // console.log('res',res);
        this.airportlink = res.data.data; 
      })
      let queryParams1 = {
         perpage: 1000,
        offset:0
       
      }
     var data1 = JSON.stringify(queryParams1);
      axios.post('zone/v1/lists/',data1)
      .then(res => { 
       // console.log('res',res);
        this.zone = res.data.data; 
      })
      let queryParams = {
         perpage: 100,
        offset:0,
        type_code:1,
        keyword:null
      }
     var data = JSON.stringify(queryParams);
      axios.post('facility/v1/lists',data)
      .then(res => { 
        
        this.facilities = res.data.data; 
        
      })
      // axios.get('facility/v1/lists')
      // .then(res => { 
      //  // console.log('res',res);
      //   this.facilities = res.data.data; 
      // })
      
      
  },
  methods: {
    async getDevelops(){
      let params = {
        perpage: 500,
        offset:0,
        //type_code:'mrt',
        keyword:'',
      }
      var data = JSON.stringify(params);
      let devs = await axios.post('developer/v1/lists', data);
      if(devs.data.data){
        this.devs = devs.data.data; 
      }

    },
    async getBts(){
      let bts_id = await axios.get('place/v1/get-place-bts/'+this.id);
     
      if(bts_id.data.data){
        if(this.bts.length == 0){
            let btses = await axios.get('transit/v1/listtype/bts');
          
            bts_id.data.data.map(async data=>{
              var bts = btses.data.data.filter(j => j.id == data.id);
              if(bts){
                this.input.bts_id.push(bts[0]);
              }

            });
            
           
          }else{
            bts_id.data.data.map(async data=>{
              var bts = this.bts.filter(j => j.id == data.id);
              if(bts){
                this.input.bts_id.push(bts[0]);
              }

            });
            

          }
        /*this.input.bts_id = bts.data.data;*/
       
        
      }
     

    },
    async getMrt(){
      let mrt_id = await axios.get('place/v1/get-place-mrt/'+this.id);
     
      if(mrt_id.data.data){
        ///this.input.mrt_id = mrt.data.data;
        if(this.mrt.length == 0){
          let mrts = await axios.get('transit/v1/listtype/mrt');
          mrt_id.data.data.map(async data=>{
            
            var mrt = mrts.data.data.filter(j => j.id == data.id);
            if(mrt){
              this.input.mrt_id.push(mrt[0]);
              }

            });
            
          
           
          }else{
            mrt_id.data.data.map(async data=>{
            
            var mrt = this.mrt.filter(j => j.id == data.id);
            if(mrt){
              this.input.mrt_id.push(mrt[0]);
              }

            });

          }
      }

    },
    async getZone(){
      let zone_id = await axios.get('place/v1/get-place-zone/'+this.id);
      if(zone_id.data.data){
        if(this.zone.length == 0){
          let queryParams1 = {
              perpage: 1000,
              offset:0
            
            }
          var data1 = JSON.stringify(queryParams1);
          let zones = await axios.post('zone/v1/lists',data1);
          zone_id.data.data.map(async data=>{
            
            var zone = zones.data.data.filter(j => j.id == data.id);
            if(zone){
              this.input.zone_id.push(zone[0]);
            }

          });
        }else{
          zone_id.data.data.map(async data=>{
            
            var zone = this.zone.filter(j => j.id == data.id);
            if(zone){
              this.input.zone_id.push(zone[0]);
            }

          });

        }

      }
    },
     async getTypes(){
            const types = await axios.get('place/v1/type');
           // cconsole.log('data',types.data);
          //let data = await  axios.get(`http://realvestor.co/api/place/v1/type`);
          if(types.data.data){
             this.types = types.data.data;
          }
           
           // console.log('data',types.data);

    },
    async getFacs(){
        let facs = await axios.get('place/v1/getfacilities/'+this.id);
        let fa = [];

        if(facs.data.data){
          facs.data.data.filter(item => {
            fa.push(item.id);
          });
        }
        this.input.facility_id = fa;
    },
    async getData(){
     
      var place = await axios.get('place/v1/getdetail/'+this.id);
      
     
      if(!place.data.data.id){
        
        this.$router.push('/place/lists', () => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `ERror`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `Data not Found.`,
                },
              })
          });


      }else{
          let pl = place.data.data
          this.input.name =  pl.name
          this.input.description =  pl.description
          this.input.lat = pl.lat;
          this.input.lon = pl.lng;
          this.input.year = pl.year;
          this.input.price_sqm = pl.price_sqm;
          this.input.level = pl.level;
          this.input.comment = pl.remark;
          this.input.bts_distance = pl.bts_distance;
          this.input.mrt_distance = pl.mrt_distance;
          this.input.airportlink_distance = pl.airportlink_distance;
          this.input.rating = pl.rating;
          this.input.devlop_id = pl.developer_id;
          
          //this.input.type_id = pl.type_id;
          this.input.is_active = pl.is_active?true:false;
          if(pl.type_id){
              this.input.type_id = {
                id : pl.id,
                //name : pl.type_name
              }
              if(this.types.length == 0){
                 const types = await axios.get('place/v1/type');
                 let type_data = types.data.data.filter(j => j.id == pl.type_id);
                 
                 if(type_data){
                 this.input.type_id =type_data[0];
                }
              }else{
                let type_data = this.types.filter(j => j.id == pl.type_id);
                 
                 if(type_data){
                 this.input.type_id =type_data[0];
                }

              }
              
          }
        
         // this.type_id = pl.type_name;
          this.input.created_by = pl.created_by;
          this.created_by = pl.created_name;
          /*if(pl.bts_id){
            if(this.bts.length == 0){
              let btses = await axios.get('transit/v1/listtype/bts');
              var bts = btses.data.data.filter(j => j.id == pl.bts_id);
              if(bts){
                this.input.bts_id.push(bts[0]);
              }
            }else{
              var bts = this.bts.filter(j => j.id == pl.bts_id);
                if(bts){
                  this.input.bts_id.push(bts[0]);
                }

            }

          }*/
          /*if(pl.mrt_id){
            if(this.mrt.length == 0){
              let mrts = await axios.get('transit/v1/listtype/mrt');
              var mrt = mrts.data.data.filter(j => j.id == pl.mrt_id);
              if(mrt){
                this.input.mrt_id.push(mrt[0]);
                }
            }else{
              var mrt = this.mrt.filter(j => j.id == pl.mrt_id);
                if(mrt){
                  this.input.mrt_id.push(mrt[0]);
                }

            }

          }*/
          if(pl.airport_id){
            
            if(this.airportlink.length == 0){
              let airports = await axios.get('transit/v1/listtype/airport');
              var airport = airports.data.data.filter(j => j.id == pl.airport_id);
              if(airport){
                this.input.airportlink_id =airport[0];
                }
            }else{
              var airport = this.airportlink.filter(j => j.id == pl.airport_id);
                if(airport){
                  this.input.airportlink_id =airport[0];
                }

            }

          }
         /* if(pl.zone_id){
            
            if(this.zone.length == 0){
              let queryParams1 = {
                  perpage: 1000,
                  offset:0
                
                }
              var data1 = JSON.stringify(queryParams1);
              let zones = await axios.post('zone/v1/lists',data1);
              var zone = zones.data.data.filter(j => j.id == pl.zone_id);
              if(zone){
                this.input.zone_id.push(zone[0]);
                }
            }else{
              var zone = this.zone.filter(j => j.id == pl.zone_id);
                if(zone){
                  this.input.zone_id.push(zone[0]);
                }

            }

          }*/
         
          if(pl.lat && pl.lng){
          this.center = [pl.lat,pl.lng]
          this.markerLatLng = [pl.lat,pl.lng]
          this.circle.center = [pl.lat,pl.lng]
        
        }


      }
      
    },
    
    changelatlon(){
     
      //13.7314081,100.5392509
      if(this.input.lat && this.input.lon){
        this.center = [this.input.lat,this.input.lon]
        this.markerLatLng = [this.input.lat,this.input.lon]
        this.circle.center = [this.input.lat,this.input.lon]
        
      }

    },
    makeToast(variant = null,title,message) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if(this.input.type_id.id){
            this.input.type_id = this.input.type_id.id;
          }
             
          var data = JSON.stringify(this.input);
          if(this.id != undefined){
            axios.put('place/v1/update/'+this.id,data)
              .then(res => {
                if(!res.data.success){
                  this.makeToast('danger','Error 403',res.data.message);
                }else{
                  this.$router.push('/place/lists', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Done`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully updated as .`,
                        },
                      })
                  });
                }
              }).catch(error => {
                this.makeToast('danger','Error 403',res.data.message);
              })
          }else{
            axios.post('place/v1/add',data)
              .then(res => {
                if(!res.data.success){
                  this.makeToast('danger','Error 403',res.data.message);
                }else{
                  this.$router.push('/place/lists', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Done`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully created.`,
                        },
                      })
                  });
                }
              }).catch(error => {
                  this.makeToast('danger','Error 403',error.response.data.message);
              })
          }
        }
      })
    },
    fetchResults() {
      const { devlop_id } = this
      console.log('query',devlop_id);
      if(devlop_id){

      
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            var data = JSON.stringify({
                "keyword": devlop_id
                });
            const placePromise = axios.post('autocomplete/v1/users',data)
          
            

            Promise.all([placePromise]).then(values => {
                this.suggestions = []
              this.selected =null;
                console.log('values', values[0].data.data);
                var data =  values[0].data.data;
                this.suggestions.push({ name: 'dev', data: data })
              
              // this.suggestions.push();
            }).catch(error=>{
                console.log('error', error)
            })
          }, this.debounceMilliseconds)
      }else{
          this.suggestions = []
      }
    },
    
    filterResults(data, text, field) {
      return data.filter(item => {
        if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          return item[field]
        }
        return false
      }).sort()
    },
    renderSuggestion(suggestion) {
        console.log('suggestion',suggestion);
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { name, item } = suggestion
     
      return name === 'hotels' ? item.title : item.name
    },
    fetchResultsType() {
      const { type_id } = this
      console.log('query',type_id);
      if(type_id){

      
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            var data = JSON.stringify({
                "keyword": type_id
                });
            const placePromise = axios.post('autocomplete/v1/placetype',data)
          
            

            Promise.all([placePromise]).then(values => {
                this.suggestionsType = []
              
                console.log('values', values[0].data.data);
                var data =  values[0].data.data;
                this.suggestionsType.push({ name: 'types', data: data })
              
              // this.suggestions.push();
            }).catch(error=>{
                console.log('error', error)
            })
          }, this.debounceMilliseconds)
      }else{
          this.suggestionsType = []
      }
    },
    
    filterResultsType(data, text, field) {
      return data.filter(item => {
        if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          return item[field]
        }
        return false
      }).sort()
    },
    renderSuggestionType(suggestion) {
        console.log('suggestion',suggestion);
      return suggestion.item.name
    },
    getSuggestionValueType(suggestion) {
      const { name, item } = suggestion
     
      return name === 'hotels' ? item.title : item.name
    },
    fetchResultscb() {
      const { created_by } = this
      
      if(created_by){

      
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            var data = JSON.stringify({
                "keyword": created_by
                });
            const placePromise = axios.post('autocomplete/v1/users',data)
          
            

            Promise.all([placePromise]).then(values => {
                this.suggestionscb = []
             
               
                var data =  values[0].data.data;
                this.suggestionscb.push({ name: 'cb', data: data })
              
              // this.suggestions.push();
            }).catch(error=>{
                console.log('error', error)
            })
          }, this.debounceMilliseconds)
      }else{
          this.suggestionscb = []
      }
    },
    
    filterResultscb(data, text, field) {
      return data.filter(item => {
        if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          return item[field]
        }
        return false
      }).sort()
    },
    renderSuggestioncb(suggestion) {
        console.log('suggestion',suggestion);
      return suggestion.item.name
    },
    getSuggestionValuecb(suggestion) {
      const { name, item } = suggestion
      
      return name === 'hotels' ? item.title : item.name
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>